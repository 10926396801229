import { useTranslation } from 'react-i18next';
import { cnb } from 'cnbuilder';

import { useAppDispatch, useAppSelector } from 'Hooks/redux-hooks';

import { resetOrdersFilters, toggleOrdersFiltersSidebar } from 'Store/orders/actions';
import { selectOrdersFiltersForTags$ } from 'Store/orders/selectors';

import FiltersSidebar from 'Components/@Shared/FiltersSidebar';
import ActiveFilters from 'Components/@Shared/ActiveFilters';

import Filters from 'Containers/Groups/components/FiltersContainer/components/Filters';

import styles from 'Components/@Styles/FiltersContainer.module.scss';

const FiltersContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => selectOrdersFiltersForTags$(state));
  const { filtersActive, filtersOpen } = useAppSelector((state) => state.orders);
  const handleClearFilters = () => {
    dispatch<any>(resetOrdersFilters());
  };
  const handleClose = (state) => {
    dispatch<any>(toggleOrdersFiltersSidebar(state));
  };
  return (
    <>
      <div className={cnb(filtersActive && styles.ActiveFiltersContainer)}>
        <ActiveFilters
          clearFiltersLabel={t('Wyczyść wszystko')}
          onClearFilters={handleClearFilters}
          filters={filters}
          heading={t('Aktywne filtry')}
          filtersActive={filtersActive}
        />
      </div>

      <FiltersSidebar onClose={handleClose} filtersOpen={filtersOpen}>
        <Filters />
      </FiltersSidebar>
    </>
  );
};

export default FiltersContainer;
