import { useTranslation } from 'react-i18next';

import Tag from 'Components/@Shared/ActiveFilters/components/ActiveFiltersTags/components/Tag';

import styles from './ActiveFiltersTags.module.scss';

type ActiveFiltersTagProps = {
  filters: {};
};

const ActiveFiltersTag = ({ filters }: ActiveFiltersTagProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Container}>
      {Object.keys(filters).map((key) =>
        filters[key].value ? (
          <Tag
            key={key}
            id={key}
            label={t(filters[key].label)}
            value={t(filters[key].value)}
            onDelete={filters[key].handleDelete}
          />
        ) : null,
      )}
    </div>
  );
};
export default ActiveFiltersTag;
