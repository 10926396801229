export const schemasDataFinance = [
  {
    title: 'Finanse 1.0',
    type: 'finanse',
    id: 1,
    level: 'podstawowy',
    autor: 'Karol Froń',
    created_at: '2023-08-09',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.1',
    type: 'finanse',
    id: 2,
    level: 'zaawansowany',

    autor: 'Karol Froń',
    created_at: '2023-08-09',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.2',
    type: 'finanse',
    id: 3,
    level: 'melancholik',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.3',
    type: 'finanse',
    id: 4,
    level: 'choleryk',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Finanse 1.4',
    type: 'finanse',
    id: 5,
    level: 'sangwinik',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
];
export const schemasDataSafety = [
  {
    title: 'Ubezpieczenia 1.0',
    type: 'safety',
    id: 10,
    level: 'podstawowy',

    autor: 'Karol Froń',
    created_at: '2023-08-10',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.1',
    type: 'safety',
    id: 11,
    level: 'zaawansowany',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.2',
    type: 'safety',
    id: 12,
    level: 'melancholik',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.3',
    type: 'safety',
    id: 13,
    level: 'choleryk',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
  {
    title: 'Ubezpieczenia 1.4',
    type: 'safety',
    id: 14,
    level: 'sangwinik',

    autor: 'Karol Froń',
    created_at: '2023-08-13',
    updated_at: '2023-08-24',
  },
];
