import API from 'Lib/axios';
import { Dispatch } from '@reduxjs/toolkit';

import { RootState } from 'Store/store';

import { ORDER_ENDPOINTS } from 'Constants/endpoints';

import { formDataToString } from 'Utils/common';

import * as actionTypes from 'Store/orders/actionTypes';
import { selectOrdersFilters$ } from 'Store/orders/selectors';

export const toggleOrdersFiltersSidebarAction = (filtersOpen: boolean) => ({
  type: actionTypes.TOGGLE_FILTERS_SIDEBAR,
  payload: {
    filtersOpen,
  },
});

export const toggleOrdersFiltersSidebar = (filtersOpen?: boolean) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    if (typeof filtersOpen !== 'undefined') {
      dispatch(toggleOrdersFiltersSidebarAction(filtersOpen));
    } else {
      const { filtersOpen: open } = getState().orders;

      dispatch(toggleOrdersFiltersSidebarAction(!open));
    }
  };
};

/** ORDERS ############################################################################################################ */
export const setOrdersAction = (orders: Array<{}>, total_value: number, total_paid: number, total: number) => ({
  type: actionTypes.SET_ORDERS,
  payload: {
    orders,
    total_value,
    total_paid,
    total,
  },
});

export const setOrdersCurrentPageAction = (currentPage: number) => ({
  type: actionTypes.SET_CURRENT_PAGE,
  payload: {
    currentPage,
  },
});

export const setOrdersFiltersAction = (filters: {}) => ({
  type: actionTypes.SET_FILTERS,
  payload: {
    filters,
  },
});

export const setOrdersFiltersParamsAction = (filtersParams: {}) => ({
  type: actionTypes.SET_FILTERS_PARAMS,
  payload: {
    filtersParams,
  },
});

export const resetOrdersFiltersAction = () => ({
  type: actionTypes.RESET_FILTERS,
});

export const setOrdersReadyAction = () => ({
  type: actionTypes.SET_ORDERS_READY,
});

export const clearOrdersAction = () => ({
  type: actionTypes.CLEAR_ORDERS,
});

export const fetchOrdersWithFilters = (skip: number, limit: number, paramsString: any) => {
  const params = paramsString.length ? `?${paramsString}` : '';
  return API.get(`${ORDER_ENDPOINTS.ORDERS}${params}`, {
    headers: {
      skip,
      limit,
    },
  });
};

export const getOrdersWithFilters = (skip: number, limit: number, paramsString: any, init = false) => {
  return (dispatch: Dispatch) => {
    fetchOrdersWithFilters(skip, limit, paramsString).then((res) => {
      const { orders, total_value, total_paid, total } = res.data;
      dispatch(setOrdersAction(orders, total_value, total_paid, total));
      if (init) {
        dispatch(setOrdersReadyAction());
      }
    });
  };
};

export const changeOrdersCurrentPage = (page: number) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters = selectOrdersFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);
    const skip = (page - 1) * itemsPerPage;

    dispatch(setOrdersCurrentPageAction(page));
    dispatch(setOrdersFiltersParamsAction(paramsString));
    dispatch<any>(getOrdersWithFilters(skip, itemsPerPage, paramsString));
  };
};

export const removeOrdersFilter = (id: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(setOrdersFiltersAction({ [id]: '' }));

    const filters = selectOrdersFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setOrdersCurrentPageAction(1));
    dispatch(setOrdersFiltersParamsAction(paramsString));
    dispatch<any>(getOrdersWithFilters(0, itemsPerPage, paramsString));
  };
};

export const resetOrdersFilters = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(resetOrdersFiltersAction());

    const filters = selectOrdersFilters$(getState());
    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setOrdersCurrentPageAction(1));
    dispatch(setOrdersFiltersParamsAction(paramsString));
    dispatch<any>(getOrdersWithFilters(0, itemsPerPage, paramsString));
  };
};

export const sortByDate = () => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const filters = selectOrdersFilters$(getState());
    filters.date_sort = filters.date_sort === 'desc' ? 'asc' : 'desc';

    const { itemsPerPage } = getState().app;
    const paramsString = formDataToString(filters);

    dispatch(setOrdersCurrentPageAction(1));
    dispatch(setOrdersFiltersParamsAction(paramsString));
    dispatch<any>(getOrdersWithFilters(0, itemsPerPage, paramsString));
  };
};
