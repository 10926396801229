import Cookies from 'react-cookies';
import { toastr } from 'react-redux-toastr';
import { Dispatch } from '@reduxjs/toolkit';

import history from 'Lib/history';
import API from 'Lib/axios';

import * as actionTypes from 'Store/user/actionTypes';

import { LOGIN } from 'Constants/routes';
import { ACCOUNT_ENDPOINTS } from 'Constants/endpoints';

import { COOKIE_SID, COOKIE_SID_MAXAGE } from 'Utils/cookies';

export const setLoggedInAction = (loggedIn: boolean) => ({
  type: actionTypes.SET_LOGGED_IN,
  payload: {
    loggedIn,
  },
});

export const logoutAction = (loggedOut: boolean) => ({
  type: actionTypes.LOGOUT,
  payload: {
    loggedOut,
  },
});

export const setDetailsAction = (details: {}) => ({
  type: actionTypes.SET_DETAILS,
  payload: {
    details,
  },
});

export const setDashboardReadyAction = () => ({
  type: actionTypes.SET_DASHBOARD_READY,
});

export const fetchDetails = () => {
  return API.get(ACCOUNT_ENDPOINTS.DETAILS);
};

export const fetchSummary = () => {
  return API.get(ACCOUNT_ENDPOINTS.SUMMARY);
};

export const getDetails = () => {
  return (dispatch: Dispatch) => {
    fetchDetails().then((res) => {
      const { details } = res.data;

      dispatch(setDetailsAction(details));
    });
  };
};

export const login = (data: { status: string; user_id: number; token: string }, destination: string | null) => {
  return async (dispatch: Dispatch) => {
    const { token } = data;
    Cookies.save(COOKIE_SID, token, { maxAge: COOKIE_SID_MAXAGE });

    fetchDetails().then((res) => {
      const { details } = res.data;
      details.token = token;
      dispatch(setDetailsAction(details));
      dispatch(setLoggedInAction(true));
      toastr.success('sukces', 'Zostałeś pomyślnie zalogowany');

      if (destination) {
        history.push(destination);
      }
    });
  };
};

export const logout = (actionData: boolean) => {
  return async (dispatch: Dispatch) => {
    await API.post(ACCOUNT_ENDPOINTS.LOGOUT, { data: { Authorization: `${Cookies.load(COOKIE_SID)}` } })
      .then(async () => {
        dispatch(logoutAction(actionData));
        dispatch(setLoggedInAction(false));

        Cookies.remove(COOKIE_SID);
        // TODO: toastr Add message in backend
        toastr.success('sukces', 'Zostałeś pomyślnie wylogowany');
        history.replace(LOGIN);
      })
      .catch((err) => {
        const { data } = err.response || {};

        if (data) {
          toastr.error(data.title, data.message);
        }
      });
  };
};
