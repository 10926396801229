import { SCHEMAS_ENDPOINTS } from 'Constants/endpoints';
import API from 'Lib/axios';
import * as actionTypes from 'Store/schemas/actionTypes';
import { Dispatch } from '@reduxjs/toolkit';
// import { API_URL } from 'config';

export const setSchemasAction = (schemas: Array<{}>) => ({
  type: actionTypes.SET_SCHEMAS,
  payload: {
    schemas,
  },
});

export const clearSchemasAction = () => ({
  type: actionTypes.CLEAR_SCHEMAS,
});

export const fetchSchemas = () => {
  return API.get(SCHEMAS_ENDPOINTS.SCHEMAS);
};

export const setSchemasReadyAction = () => ({
  type: actionTypes.SET_SCHEMAS_READY,
});

export const getSchemas = (init = false) => {
  return (dispatch: Dispatch) => {
    fetchSchemas().then((res) => {
      const { schemas } = res.data;
      dispatch(setSchemasAction(schemas));
      if (init) {
        dispatch(setSchemasReadyAction());
      }
    });
  };
};

//  SHEMA

export const setSchemaAction = (schema: Array<{}>) => ({
  type: actionTypes.SET_SCHEMA,
  payload: {
    schema,
  },
});

export const clearSchemaAction = () => ({
  type: actionTypes.CLEAR_SCHEMA,
});

export const fetchSchema = (id) => {
  // return API.get(`${SCHEMA_ENDPOINTS.SCHEMA}/${id}`);

  return API.post(`/user/bot/start/${id}`);
  // return fetch(`https://mementor-35734ec73d5c.herokuapp.com/schemas/${id}`);
};

export const setSchemaReadyAction = () => ({
  type: actionTypes.SET_SCHEMA_READY,
});

export const getSchema = (id: number, init = false) => {
  return (dispatch) => {
    fetchSchema(id).then((res) => {
      // console.log('res', res);
      const { schema } = res?.data;
      dispatch(setSchemaAction(schema));
      if (init) {
        dispatch(setSchemaReadyAction());
      }
    });
  };
  // return (dispatch: Dispatch) => {
  //   fetchSchema(id).then((res) => {
  //     const { schema } = res.data;
  //     dispatch(setSchemaAction(schema));
  //     if (init) {
  //       dispatch(setSchemaReadyAction());
  //     }
  //   });
  // };
};
