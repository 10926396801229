import moment from 'moment';
import { cloneDeep as _cloneDeep } from 'lodash';

import { removeOrdersFilter } from 'Store/orders/actions';

import { store } from 'Store/store';

import { DATE_FORMAT_WITHOUT_HOURS } from 'Constants';

export const prepareOrdersFiltersForTags = (
  filters: { [key: string]: any },
  halls: { [key: string]: any },
  events: { [key: string]: any },
  statuses: { [key: string]: any },
  accounts: { [key: string]: any },
  pay_statuses: { [key: string]: any },
  sources: { [key: string]: any },
) => {
  const result = _cloneDeep(filters);

  Object.keys(result).forEach((key) => {
    if (result[key].skipActive) {
      delete result[key];

      return;
    }

    if (key === 'hall') {
      result[key].value = halls[result[key].value];
    }
    if (key === 'event') {
      result[key].value = events[result[key].value];
    }
    if (key === 'status' && result[key].value) {
      result[key].value = result[key].value.map((i) => statuses[i.value]).join(', ');
    }
    if (key === 'pay_status') {
      result[key].value = pay_statuses[result[key].value];
    }
    if (key === 'created_by') {
      result[key].value = accounts[result[key].value];
    }
    if (key === 'source') {
      result[key].value = sources[result[key].value];
    }

    if (key === 'term_from' && result[key].value) {
      const date = new Date(result[key].value);
      const momentDate = moment(date.toISOString());

      result[key].value = momentDate.format(DATE_FORMAT_WITHOUT_HOURS);
    }

    if (key === 'term_to' && result[key].value) {
      const date = new Date(result[key].value);
      const momentDate = moment(date.toISOString());

      result[key].value = momentDate.format(DATE_FORMAT_WITHOUT_HOURS);
    }

    if (key === 'created_at_from' && result[key].value) {
      const date = new Date(result[key].value);
      const momentDate = moment(date.toISOString());

      result[key].value = momentDate.format(DATE_FORMAT_WITHOUT_HOURS);
    }

    if (key === 'created_at_to' && result[key].value) {
      const date = new Date(result[key].value);
      const momentDate = moment(date.toISOString());

      result[key].value = momentDate.format(DATE_FORMAT_WITHOUT_HOURS);
    }

    result[key].handleDelete = (id: number) => {
      store.dispatch<any>(removeOrdersFilter(id));
    };
  });

  return result;
};
