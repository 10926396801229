import { ReactComponent as CloseIcon } from 'Assets/icons/icon_close.svg';

import styles from './Tag.module.scss';

type TagProps = {
  id: string | number;
  label: string;
  value: string | number;
  onDelete: any;
};

const Tag = ({ id, label, value, onDelete }: TagProps) => (
  <div className={styles.Tag}>
    <strong>{label}:</strong>
    <span>{value}</span>
    <button className={styles.Tag__delete} type="button" onClick={() => onDelete(id)}>
      <CloseIcon />
    </button>
  </div>
);

export default Tag;
