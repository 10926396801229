import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'Hooks/redux-hooks';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';
import NoResults from 'Components/@Shared/NoResults';
import FiltersContainer from 'Containers/Groups/components/FiltersContainer';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';

import book1 from 'Assets/images/book1.webp';
import book2 from 'Assets/images/book2.webp';
import book3 from 'Assets/images/book3.webp';
import book4 from 'Assets/images/book4.webp';

const books = [
  {
    id: 1,
    image: book1,
    url: 'https://drive.google.com/file/d/1gIh7mRY6pp0qpQBKhhGMiLT9lEEqM7o_/view',
    title: 'LEAD GENERATION 201 złotych rad pozyskiwania klientów przez telefon',
    description: `Czy chciałbyś już od jutra sprzedawać o 35% więcej niż do tej pory? Ta książka jest dla Ciebie. To zbiór konkretnych wskazówek i tipów, których zastosowanie w rozmowie handlowej, da Ci natychmiastowe efekty.

Jak widzisz książka ta zawiera szereg konkretnych, prostych do wdrożenia wskazówek, których zastosowanie w Twoich rozmowach pozwoli Ci osiągać ponadprzeciętne rezultaty w zakresie pracy z telefonem w kontakcie z klientem.

Nie jest to fizyka kwantowa, która przyprawi Cię o mdłości, a zbiór najbardziej przydatnej wiedzy, która uczyni Cię prawdziwym sprzedawcą z krwi i kości, osiągającym najlepsze wyniki sprzedażowe w całej firmie, ponieważ w każdym z rozdziałów znajdziesz aż kilkadziesiąt podpowiedzi, w jaki sposób się komunikować, negocjować i prowadzić swobodną, a zarazem skuteczną rozmowę z klientem.
`,
  },
  {
    id: 2,
    image: book2,
    url: 'https://drive.google.com/file/d/1PIgDrXX9ypXzYN8wJQAZ8APi6byvP5Us/view',
    title: 'Najskuteczniejsze Techniki Sprzedaży, Perswazji i Negocjacji - wszech czasów',

    description: `W tym poradniku zaprezentuję Ci kilkaset najlepszych technik sprzedaży telefonicznej i bezpośredniej, które zostały wymyślone na przestrzeni ostatnich stu lat!

Pamiętaj jednak, że to nie techniki sprzedają, tylko Ty, więc może się okazać,że jedna technika przypasuje Ci bardziej, a inna mniej lub jedna będzie działać na określony typ klienta, a inna nie. Tak czy inaczej wiedza, którą otrzymasz może uczynić Cię ekspertem od sprzedaży i dać Ci nieporównywalnie większe efekty w sprzedaży niż teraz. Baw się tą wiedzą, łącz ze sobą, mieszaj, próbuj stosować w różnych konfiguracjach i nie zrażaj się, jeśli nie odniesiesz od razu sukcesu.

Metody, które przekażę Ci w tym poradniku działają i zostały wielokrotnie przetestowane przeze mnie podczas mojej 11 letniej kariery jako sprzedawca, menadżer, przedsiębiorca.

Część z tych technik może być postrzegana jako nieetyczna lub przypomina manipulację.

Nie ma jednak rzeczy z gruntu złych bądź dobrych, to ludzie nadają im znaczenie.

Możesz więc używać tych metod w dobrym bądź niecnym celu - to zależy tylko od Ciebie.
`,
  },
  {
    id: 3,
    image: book3,
    url: 'https://drive.google.com/file/d/1pQeTOYhdUBDMBeSq3hmRqTOdUOjqGtyJ/view',
    title: 'Typologia Osobowości część I',
    description: `Wyobraź sobie, że zaczynasz spotkanie sprzedażowe z potencjalnym klientem i w ciągu kilku minut rozumiesz, z kim masz do czynienia. 

Ponieważ znasz jego typ osobowości, wiesz dokładnie, co i jak powiedzieć, aby zbudować relację. Dzięki temu skutecznie przedstawiasz ofertę w taki sposób, aby najlepiej do niego przemówiła, a następnie domykasz sprzedaż.

Pamiętaj, że nie chodzi tutaj o zmianę tego, kim TY jesteś, tylko o zrozumienie, kim oni są i danie im tego, czego potrzebują!

Koniec ze zgadywaniem. Koniec z zastanawianiem się, dlaczego nie reagują. Otrzymasz ode mnie konkretne techniki i metody, które przynoszą rezultaty.
`,
  },
  {
    id: 4,
    image: book4,
    url: 'https://drive.google.com/file/d/1W8wHJfp5t4CyAqzCIviuAynmbNQ7e19q/view',
    title: 'Typologia Osobowości część II',
    description: `W drugiej części dowiesz się:
jakie style komunikacji stosują poszczególne typy klientów oraz jak sobie z nimi poradzić,
zrozumiesz, jak rozwiązywać konflikty (np. w zespole lub klientami) i radzić sobie z trudnymi osobami, aby zamieniać konflikt w coś, co może Ci wyjść na dobre, 
odkryjesz też style przyswajania informacji, dzięki czemu np. tak dobierzesz materiały sprzedażowe, aby dana osoba najlepiej na nie zareagowała, 
poznasz też dwa podstawowe rodzaje motywacji oraz jak się dowiedzieć, jaki jest silniejszy u człowieka, z którym rozmawiasz. 
`,
  },
];

const Ebooks = () => {
  const { t } = useTranslation();
  const { groups, filtersActive, isReady } = useAppSelector((state) => state.coordinatorGroups);

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>
            {t('E-booki')}
            <div className={pageHeaderStyles.PageHeader__actions}>
              {/* <ItemsPerPageSelector /> */}
              {/* <ToggleFiltersButton handleToggle={handleToggleFiltersSidebar} /> */}
            </div>
          </div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <FiltersContainer />
              <Grid container spacing={2}>
                {books.map((book) => {
                  return (
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      item
                      sm={12}
                      md={6}
                    >
                      <Card onClick={() => window.open(book.url, '_blank')} key={book.id} sx={{ maxWidth: 1000 }}>
                        <CardActionArea
                          sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            backgroundColor: 'whitesmoke',
                          }}
                        >
                          <CardMedia
                            component="img"
                            height="150"
                            image={book.image}
                            alt={book.title}
                            style={{ cursor: 'pointer' }}
                          />
                          <CardContent>
                            <Typography variant="h5" mb={3} component="div">
                              {book.title}
                            </Typography>
                            <Typography component="div">{book.description}</Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              {isReady && groups.length <= 0 && <NoResults hasActiveFilters={filtersActive} />}
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default Ebooks;
