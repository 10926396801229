import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { merge as _merge } from 'lodash';

import { useAppDispatch, useAppSelector } from 'Hooks/redux-hooks';

import yup from 'Lib/yup';

import {
  fetchOrdersWithFilters,
  setOrdersAction,
  setOrdersCurrentPageAction,
  setOrdersFiltersAction,
  setOrdersFiltersParamsAction,
  toggleOrdersFiltersSidebar,
} from 'Store/orders/actions';

import { selectOrdersFilters$ } from 'Store/orders/selectors';

import { createToastr, formDataToString } from 'Utils/common';

import Button from 'Components/@Base/Button';

import InputWrapper from 'Components/@Shared/InputWrapper';

const schema = yup.object().shape({
  event: yup.string().notRequired().nullable().default(null),
  hall: yup.string().notRequired().nullable().default(null),
  term_from: yup.string().nullable().notRequired().default(null),
  term_to: yup.string().nullable().notRequired().default(null),
  created_at_from: yup.string().nullable().notRequired().default(null),
  created_at_to: yup.string().nullable().notRequired().default(null),
  status: yup.array().notRequired().nullable().default(null),
  pay_status: yup.string().notRequired().nullable().default(null),
  email: yup.string().notRequired().default(''),
  phone: yup.string().notRequired().default(''),
  last_name: yup.string().notRequired().default(''),
  company_name: yup.string().notRequired().default(''),
  nip: yup.string().notRequired().default(''),
  created_by: yup.string().notRequired().nullable().default(null),
});

const Filters = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => selectOrdersFilters$(state));
  const itemsPerPage = useAppSelector((state) => state.app.itemsPerPage);
  const { order_statuses, accounts, halls, events, pay_statuses, sources } = useAppSelector((state) => state.app);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, control, handleSubmit, errors, setValue, setError } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...filters,
    },
  });

  const HALLS_OPTIONS = Object.keys(halls).map((key) => ({ label: halls[key], value: key }));
  const EVENTS_OPTIONS = Object.keys(events).map((key) => ({ label: events[key], value: key }));
  const STATUS_OPTIONS = Object.keys(order_statuses).map((key) => ({ label: order_statuses[key], value: key }));
  const PAY_STATUS_OPTIONS = Object.keys(pay_statuses).map((key) => ({ label: pay_statuses[key], value: key }));
  const CREATED_BY_OPTIONS = Object.keys(accounts).map((key) => ({ label: accounts[key], value: key }));
  const SOURCES_OPTIONS = Object.keys(sources).map((key) => ({ label: sources[key], value: key }));

  const onSubmit = (data) => {
    const dataWithSort = _merge(filters, data);
    const paramsString = formDataToString(dataWithSort);
    setIsSubmitting(true);

    fetchOrdersWithFilters(0, itemsPerPage, paramsString)
      .then((res) => {
        const { orders, total_value, total_paid, total } = res.data;
        dispatch(setOrdersFiltersAction(data));
        dispatch(setOrdersFiltersParamsAction(paramsString));
        dispatch(setOrdersCurrentPageAction(1));
        dispatch(setOrdersAction(orders, total_value, total_paid, total));
        dispatch<any>(toggleOrdersFiltersSidebar(false));

        setIsSubmitting(false);
      })
      .catch((err) => {
        const { data: errorData, status } = err.response;

        createToastr(errorData, status, setError);

        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    Object.keys(filters).forEach((key) => {
      setValue(key, filters[key]);
    });
  }, [setValue, filters]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <InputWrapper
        className="mb-3"
        componentType="select"
        labelProps={{ label: t('Event') }}
        selectProps={{
          control,
          name: 'event',
          defaultValue: '',
          placeholder: t('Wybierz'),
          options: EVENTS_OPTIONS,
          onChange: (selected) => (selected ? selected.value : null),
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
        }}
        feedback={{
          message: t(errors.event?.message, { field: t('Hall') }),
          isError: !!errors.event,
        }}
      />
      <InputWrapper
        className="mb-3 mt-3"
        componentType="select"
        labelProps={{ label: t('Hall') }}
        selectProps={{
          control,
          name: 'hall',
          defaultValue: '',
          placeholder: t('Wybierz'),
          options: HALLS_OPTIONS,
          onChange: (selected) => (selected ? selected.value : null),
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
        }}
        feedback={{
          message: t(errors.hall?.message, { field: t('Hall') }),
          isError: !!errors.hall,
        }}
      />
      <div className="d-flex justify-content-between w-100">
        <InputWrapper
          className="mb-3 mr-4 w-100"
          componentType="datetimepicker"
          labelProps={{ label: t('Term from') }}
          dateTimePickerProps={{
            control,
            defaultValue: '',
            name: 'term_from',
            onChange: (selected) => selected,
            inputFormat: 'YYYY/MM/DD',
            views: ['year', 'day'],
          }}
          feedback={{
            message: t(errors.term_from?.message, { field: t('Term from') }),
            isError: !!errors.term_from,
          }}
        />
        <InputWrapper
          className="mb-3 w-100"
          componentType="datetimepicker"
          labelProps={{ label: t('Term to') }}
          dateTimePickerProps={{
            control,
            defaultValue: '',
            name: 'term_to',
            onChange: (selected) => selected,
            inputFormat: 'YYYY/MM/DD',
            views: ['year', 'day'],
          }}
          feedback={{
            message: t(errors.term_to?.message, { field: t('Term to') }),
            isError: !!errors.term_to,
          }}
        />
      </div>

      <InputWrapper
        className="mb-3"
        componentType="select"
        labelProps={{ label: t('Status') }}
        selectProps={{
          control,
          name: 'status',
          placeholder: t('Wybierz'),
          options: STATUS_OPTIONS,
          onChange: (selected) => selected || null,
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
          isMulti: true,
        }}
        feedback={{
          message: t(errors.status?.message, { field: t('Status') }),
          isError: !!errors.status,
        }}
      />
      <div className="d-flex justify-content-between w-100">
        <InputWrapper
          className="mb-3 mr-4 w-100"
          componentType="datetimepicker"
          labelProps={{ label: t('Utworzono od') }}
          dateTimePickerProps={{
            control,
            name: 'created_at_from',
            onChange: (selected) => selected,
            disableClock: true,
            inputFormat: 'YYYY/MM/DD',
            views: ['year', 'day'],
          }}
          feedback={{
            message: t(errors.created_at_from?.message, { field: t('Utworzono od') }),
            isError: !!errors.created_at_from,
          }}
        />
        <InputWrapper
          className="mb-3 w-100"
          componentType="datetimepicker"
          labelProps={{ label: t('Utworzono do') }}
          dateTimePickerProps={{
            control,
            name: 'created_at_to',
            onChange: (selected) => selected,
            disableClock: true,
            inputFormat: 'YYYY/MM/DD',
            views: ['year', 'day'],
          }}
          feedback={{
            message: t(errors.created_at_to?.message, { field: t('Utworzono do') }),
            isError: !!errors.created_at_to,
          }}
        />
      </div>
      <InputWrapper
        className="mb-3"
        componentType="select"
        labelProps={{ label: t('Pay status') }}
        selectProps={{
          control,
          name: 'pay_status',
          placeholder: t('Wybierz'),
          options: PAY_STATUS_OPTIONS,
          onChange: (selected) => (selected ? selected.value : null),
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
        }}
        feedback={{
          message: t(errors.pay_status?.message, { field: t('Pay status') }),
          isError: !!errors.pay_status,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="input"
        labelProps={{ label: t('Email') }}
        inputProps={{
          innerRef: register,
          name: 'email',
          type: 'text',
          placeholder: t('Email'),
          setValue,
        }}
        feedback={{
          message: t(errors.email?.message, { field: t('Email') }),
          isError: !!errors.email,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="input"
        labelProps={{ label: t('Telefon') }}
        inputProps={{
          innerRef: register,
          name: 'phone',
          type: 'text',
          placeholder: t('Telefon'),
          setValue,
        }}
        feedback={{
          message: t(errors.phone?.message, { field: t('Telefon') }),
          isError: !!errors.phone,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="input"
        labelProps={{ label: t('Imię') }}
        inputProps={{
          innerRef: register,
          name: 'last_name',
          type: 'text',
          placeholder: t('Imię'),
          setValue,
        }}
        feedback={{
          message: t(errors.last_name?.message, { field: t('Imię') }),
          isError: !!errors.last_name,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="input"
        labelProps={{ label: t('Company name') }}
        inputProps={{
          innerRef: register,
          name: 'company_name',
          type: 'text',
          placeholder: t('Company name'),
          setValue,
        }}
        feedback={{
          message: t(errors.company_name?.message, { field: t('Company name') }),
          isError: !!errors.company_name,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="input"
        labelProps={{ label: t('NIP') }}
        inputProps={{
          innerRef: register,
          name: 'nip',
          type: 'text',
          placeholder: t('NIP'),
          setValue,
        }}
        feedback={{
          message: t(errors.nip?.message, { field: t('NIP') }),
          isError: !!errors.nip,
        }}
      />
      <InputWrapper
        className="mb-3"
        componentType="select"
        labelProps={{ label: t('Utworzono przez') }}
        selectProps={{
          control,
          name: 'created_by',
          placeholder: t('Wybierz'),
          options: CREATED_BY_OPTIONS,
          onChange: (selected) => (selected ? selected.value : null),
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
        }}
        feedback={{
          message: t(errors.created_by?.message, { field: t('Utworzono przez') }),
          isError: !!errors.created_by,
        }}
      />
      <InputWrapper
        className="mb-3 w-100"
        componentType="select"
        labelProps={{ label: t('Source') }}
        selectProps={{
          control,
          name: 'source',
          placeholder: t('Wybierz'),
          options: SOURCES_OPTIONS,
          onChange: (selected) => (selected ? selected.value : null),
          getOptionLabel: (option) => t(option.label),
          isClearable: true,
          isSearchable: true,
        }}
        feedback={{
          message: t(errors.source?.message, { field: t('Source') }),
          isError: !!errors.source,
        }}
      />

      <Button className="mt-3" label={t('Filtruj')} type="submit" buttonType="primary" isSubmitting={isSubmitting} />
    </form>
  );
};

export default Filters;
