export const HOME = '/';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const REMIND_PASSWORD = '/remind-password';
export const RESET_PASSWORD = '/reset-password';
export const USER_CHANGE_PASSWORD = '/user-change-password';
export const EMAIL_VERIFICATION = '/verification-email';
export const VERIFICATION = '/verification';
export const ADMIN = '/admin';
export const NOT_FOUND = '/404';
export const PROFILE = '/profile';
export const ADMIN_SETTINGS = '/backoffice/settings';
export const ADMIN_EDIT_SETTINGS = '/backoffice/settings/edit';
export const ADMIN_USERS = '/backoffice/users';
export const ADMIN_USERS_EDIT = '/backoffice/users/edit';
export const ADMIN_USER_ADD = '/backoffice/users/add';
export const ADMIN_TRANSLATIONS = '/backoffice/translations';
export const CUSTOMERS = '/customers';
export const GROUPS = '/coordinator/groups';
export const GROUP_ADD = '/coordinator/groups/add';
export const GROUP_EDIT = '/coordinator/groups/edit';
export const USERS = '/coordinator/users';
export const USER_ADD = '/user/add';
export const SCHEMAS = '/coordinator/schemas';
export const SCHEMA = '/coordinator/schemas/schema';
export const REPORTS = '/coordinator/reports';
export const ANALITICS = '/coordinator/analytics';
export const REPORT = '/coordinator/reports/report';
export const COORDINATOR = '/coordinator';
export const CUSTOMERS_ADD = '/customers/add';
export const CUSTOMERS_EDIT = '/customers/edit';
export const CUSTOMERS_EDIT_STATUS = '/customers/edit/status';
export const ORDERS = '/orders';
export const ORDER_ADD = '/orders/add';
export const ORDER_EDIT = '/orders/edit';
export const REQUESTS = '/requests';
export const REQUEST_ADD = '/requests/add';
export const REQUEST_EDIT = '/requests/edit';
export const CALENDAR = '/calendar';
export const ADMIN_CLOSED_DAYS = '/backoffice/closed-days';
export const ADMIN_CLOSED_DAY_ADD = '/backoffice/closed-days/add';
export const LEADS = '/leads';
export const LEAD_INFO = '/leads/info';
export const DATABASE_ADD = '/database/add';
export const DATABASES = '/databases';
export const BOT = '/bot';
export const TICKETS = '/tickets';
export const TICKET = '/tickets/:ticketRef';
export const TICKETS_NEW = '/tickets/new';
export const REPORTS_EMPLOYEE = '/reports';
export const SCRIPTS = '/scripts';
export const ELEMENTS = '/elements-conversations';
export const EBOOKS = '/ebooks';
