import { useCallback, useLayoutEffect, useRef } from 'react';

import { cnb } from 'cnbuilder';

import useEventListener from 'Hooks/use-event-listener';

import { MdClose } from 'react-icons/all';
import styles from 'Components/@Shared/FiltersSidebar/FiltersSidebar.module.scss';

type FiltersSidebarProps = {
  filtersOpen: boolean;
  onClose: any;
  children: JSX.Element;
};

const FiltersSidebar = ({ filtersOpen, onClose, children }: FiltersSidebarProps) => {
  const menuRef = useRef(null);

  const handleClose = () => {
    onClose(false);
  };

  const handleClickOutside = useCallback(
    (event) => {
      const { target } = event;
      const { current: menu } = menuRef;
      if (menu && menu.contains(target)) {
        onClose(false);
      }
    },
    [onClose],
  );

  useEventListener('click', handleClickOutside);

  useLayoutEffect(() => {
    if (filtersOpen) {
      document.body.classList.add('FiltrySidebar--open');
    } else {
      document.body.classList.remove('FiltrySidebar--open');
    }
  }, [filtersOpen]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      ref={menuRef}
      className={cnb(styles.FiltersSidebar, filtersOpen && styles.FiltersSidebar_isOpen)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="d-flex justify-content-end w-100">
        <button className={cnb(styles.FiltersSidebar__close)} type="button" onClick={handleClose}>
          <MdClose />
        </button>
      </div>

      {/* <h5 className={cnb(styles.FiltersSidebar__header, 'mb-3 pt-3 pt-md-5 pb-3')}>{t('Filtrujs')}</h5> */}

      {children}
    </div>
  );
};

export default FiltersSidebar;
