import { useTranslation } from 'react-i18next';
import ActiveFiltersTag from './components/ActiveFiltersTags';
import ClearFiltersButton from './components/ClearFiltersButton';

import styles from './ActiveFilters.module.scss';

type ActiveFiltersProps = {
  heading: string;
  filters: {};
  filtersActive: boolean;
  clearFiltersLabel: string;
  onClearFilters: any;
};
const ActiveFilters = ({ heading, filters, filtersActive, clearFiltersLabel, onClearFilters }: ActiveFiltersProps) => {
  const { t } = useTranslation();
  return (
    <>
      {filtersActive && (
        <h4 className={styles.Heading}>
          <span>{t(heading)}</span>
          <ClearFiltersButton label={clearFiltersLabel} onClear={onClearFilters} />
        </h4>
      )}

      <ActiveFiltersTag filters={filters} />
    </>
  );
};

export default ActiveFilters;
