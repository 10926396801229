import { ReactComponent as CloseIcon } from 'Assets/icons/icon_close.svg';

import styles from './ClearFiltersButton.module.scss';

type ClearFiltersButtonProps = {
  label: string;
  onClear: any;
};

const ClearFiltersButton = ({ label, onClear }: ClearFiltersButtonProps) => (
  <button className={styles.Button} type="button" onClick={onClear}>
    {label}
    <CloseIcon />
  </button>
);

export default ClearFiltersButton;
