import { createSelector } from 'reselect';

import { RootState } from 'Store/store';

import { prepareFilters } from 'Utils/common';
import { prepareOrdersFiltersForTags } from 'Store/orders/helpers';

const getOrdersFilters = (state: RootState) => state.orders.filters;
const getHalls = (state: RootState) => state.app.halls;
const getEvents = (state: RootState) => state.app.events;
const getStatuses = (state: RootState) => state.app.order_statuses;
const getAccounts = (state: RootState) => state.app.accounts;
const getPayStatuses = (state: RootState) => state.app.pay_statuses;
const getSources = (state: RootState) => state.app.sources;

export const selectOrdersFilters$ = createSelector([getOrdersFilters], (filters) => {
  return prepareFilters(filters);
});

export const selectOrdersFiltersForTags$ = createSelector(
  [getOrdersFilters, getHalls, getEvents, getStatuses, getAccounts, getPayStatuses, getSources],
  (filters, halls, events, statuses, accounts, pay_statuses, sources) => {
    return prepareOrdersFiltersForTags(filters, halls, events, statuses, accounts, pay_statuses, sources);
  },
);
