import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from 'Hooks/redux-hooks';
import { GROUP_ADD } from 'Constants/routes';
import { cnb } from 'cnbuilder';

import { clearGroupsAction, getGroupsWithFilters, setGroupsCurrentPageAction } from 'Store/coordinatorGroups/actions';

import usePrevious from 'Hooks/use-previous';

import PageContainer from 'Components/@Shared/PageContainer';
import PageHeader from 'Components/@Shared/PageHeader';
import PageContent from 'Components/@Shared/PageContent';
import Block from 'Components/@Shared/Block';
import NoResults from 'Components/@Shared/NoResults';
import TableSummaryItem from 'Components/@Shared/TableSummaryItem';
import Loader from 'Components/@Shared/Loader';
import FiltersContainer from 'Containers/Groups/components/FiltersContainer';
import GroupsTable from 'Containers/Groups/components/GroupsTable';
import ButtonWrapper from 'Components/@Shared/ButtonWrapper';

import pageHeaderStyles from 'Components/@Styles/PageHeader.module.scss';
import styles from 'Components/@Styles/Table.module.scss';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';

const TABLE_STRUCTURE = {
  name: {
    header: 'Nazwa',
    className: 'col-lg',
    useEllipsis: true,
  },
  users: {
    header: 'Użytkownicy',
    className: 'col-lg',
    useEllipsis: true,
  },
  coordinator: {
    header: 'Koordynator',
    className: 'col-lg',
    useEllipsis: true,
  },
  actions: {
    header: '',
    className: cnb(styles.TableCell_Action_Groups, 'col-lg-2'),
  },
};

const videos = [
  {
    id: 1,
    title: 'Jak pokonać lęk przed dzwonieniem do klientów na zimno?',
    url: 'https://www.youtube.com/watch?v=I8fL45agdRk',
  },
  {
    id: 2,
    title: 'Opór klienta na początku rozmowy',
    url: 'https://www.youtube.com/watch?v=6qpdIh4qqYs',
  },
  {
    id: 3,
    title: 'Co zrobić aby klient nie rozłączył się już na starcie',
    url: 'https://www.youtube.com/watch?v=ub3QAfvBdbc&t=18s',
  },
  {
    id: 4,
    title: 'Hak na uwagę',
    url: 'https://www.youtube.com/watch?v=WQi-BO7FG5M',
  },
  {
    id: 5,
    title: 'Co zrobić gdy klient mówi "Nie mam czasu"',
    url: 'https://www.youtube.com/watch?v=9xQQoqo5Y9c',
  },
  {
    id: 6,
    title: 'Co zrobić gdy klient mówi "Nie potrzebuje"',
    url: 'https://www.youtube.com/watch?v=LCrO4YXruis',
  },
  {
    id: 7,
    title: 'Rozmawiaj o kliencie, a nie o produkcie',
    url: 'https://www.youtube.com/watch?v=AAoNYl4H5NY&list=PLa-v7-IDST0ywfLVnqpk4mgJjead7hl-n&index=25&t=4s',
  },
  {
    id: 8,
    title: 'Zadawaj pytania otwarte',
    url: 'https://www.youtube.com/watch?v=WRqaiMja35g&list=PLa-v7-IDST0ywfLVnqpk4mgJjead7hl-n&index=27',
  },
  {
    id: 9,
    title: 'Zawsze proś o spotkanie',
    url: 'https://www.youtube.com/watch?v=g_QD2z8y924&list=PLa-v7-IDST0ywfLVnqpk4mgJjead7hl-n&index=28',
  },
  {
    id: 10,
    title: 'Daj klientowi pozorny wybór',
    url: 'https://www.youtube.com/watch?v=wJEsWPrpa8E&list=PLa-v7-IDST0ywfLVnqpk4mgJjead7hl-n&index=29',
  },
  {
    id: 11,
    title: 'Zobowiązuj klienta bez zobowiązania',
    url: 'https://www.youtube.com/watch?v=QLn1d9uZpbE&list=PLa-v7-IDST0ywfLVnqpk4mgJjead7hl-n&index=37',
  },
  {
    id: 12,
    title: 'Jak obejść sekretarkę i połączyć z osobą osobą decyzyjną?',
    url: 'https://www.youtube.com/watch?v=zhaEG-HDB5I',
  },
  {
    id: 13,
    title: 'Najlepsza na świecie technika zamykania sprzedaży',
    url: 'https://www.youtube.com/watch?v=xJnbh3pRsN0&t=11s',
  },
  {
    id: 14,
    title: 'Skąd wziąć informacje o osobach kontaktowych przy zimnych telefonach',
    url: 'https://www.youtube.com/watch?v=xHqAJhCq8NQ',
  },
  {
    id: 15,
    title: 'Jak sprzedawać produkty Premium',
    url: 'https://www.youtube.com/watch?v=ml24GBfSOSA',
  },
  {
    id: 16,
    title: 'Klient mówi to jest za drogie, a Ty mówisz wtedy...',
    url: 'https://www.youtube.com/watch?v=0euTkp4FTdU&t=20s',
  },
];

const ElementsConversations = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { groups, total, filtersActive, filtersParams, isReady } = useAppSelector((state) => state.coordinatorGroups);
  const { itemsPerPage } = useAppSelector((state) => state.app);
  const prevItemsPerPage = usePrevious(itemsPerPage);
  const history = useHistory();

  const handleAddGroup = () => {
    history.push(GROUP_ADD);
  };

  // useEffect(() => {
  //   dispatch<any>(getGroupsWithFilters(0, itemsPerPage, '', true));
  //   return () => {
  //     dispatch(clearGroupsAction());
  //   };
  // }, [dispatch, itemsPerPage]);

  // useEffect(() => {
  //   if (prevItemsPerPage !== null && prevItemsPerPage !== itemsPerPage) {
  //     dispatch(setGroupsCurrentPageAction(1));
  //     dispatch<any>(getGroupsWithFilters(0, itemsPerPage, filtersParams));
  //   }
  // }, [dispatch, prevItemsPerPage, itemsPerPage, filtersParams]);

  // const getYoutubeVideoId = (url) => {
  //   const match =
  //     url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/) ||
  //     url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
  //   return match ? match[1] : null;
  // };

  return (
    <PageContainer>
      <>
        <PageHeader>
          <div className={pageHeaderStyles.PageHeader}>
            {t(
              'Masz problemy z poszczególnymi elementami rozmowy z klientem, zapoznaj się z odpowiednim materiałem od Karola Fronia!',
            )}
            <div className={pageHeaderStyles.PageHeader__actions}>
              {/* <ItemsPerPageSelector /> */}
              {/* <ToggleFiltersButton handleToggle={handleToggleFiltersSidebar} /> */}
            </div>
          </div>
        </PageHeader>

        <PageContent>
          <Block className="mt-0" element="div">
            <>
              <FiltersContainer />

              {/* <div className="d-flex align-items-center">
                <TableSummaryItem label={`${t('Suma')}`}>{total}</TableSummaryItem>

                <ButtonWrapper
                  className="ml-auto"
                  alignment="center"
                  label={t('Dodaj grupę')}
                  type="button"
                  buttonType="primary"
                  onClick={handleAddGroup}
                />
              </div> */}

              {true ? (
                // groups.length > 0 && (
                <>
                  {/* <GroupsTable TABLE_STRUCTURE={TABLE_STRUCTURE} groups={groups} /> */}
                  {/* <Pagination
                      current={currentPage}
                      total={total}
                      defaultPageSize={itemsPerPage}
                      pageSize={itemsPerPage}
                      onChange={handleChangePage}
                    /> */}
                  <Grid container spacing={2}>
                    {videos.map((video) => {
                      const getYoutubeVideoId = (url) => {
                        const match =
                          url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/) ||
                          url.match(/(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
                        return match ? match[1] : null;
                      };

                      const videoId = getYoutubeVideoId(video.url);
                      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
                      return (
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 2,
                          }}
                          item
                          xs={6}
                          md={4}
                          xl={2}
                        >
                          <Card onClick={() => window.open(video.url, '_blank')} key={video.id} sx={{ maxWidth: 345 }}>
                            <CardActionArea
                              sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                backgroundColor: 'whitesmoke',
                              }}
                            >
                              <CardMedia
                                component="img"
                                height="150"
                                image={thumbnailUrl}
                                alt={video.title}
                                style={{ cursor: 'pointer' }}
                              />
                              <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                  {video.title}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>

                  {/* <Card sx={{ maxWidth: 345 }}>
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image="/static/images/cards/contemplative-reptile.jpg"
                          alt="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            Lizard
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card> */}
                </>
              ) : (
                // )
                <Loader minHeight="300px" />
              )}

              {isReady && groups.length <= 0 && <NoResults hasActiveFilters={filtersActive} />}
            </>
          </Block>
        </PageContent>
      </>
    </PageContainer>
  );
};

export default ElementsConversations;
